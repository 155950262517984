import styled from "styled-components";
import { Link as LinkBase } from "react-router-dom";

import { Loader as LoaderBase } from "../../../../components/loader/loader.component";
import { AvatarType } from "../../../../components/avatar/avatar.constants";
import { Avatar as AvatarBase } from "../../../../components/avatar/avatar.component";
import { Color } from "../../../../theme/colors";
import { FontWeight } from "../../../../theme/fonts";
import { Media } from "../../../../theme/media";

export const Container = styled.div`
  padding: 35px 20px 20px;
  background-color: ${Color.white};
  flex: 1;
  overflow: hidden;

  ${Media.tablet`
    padding: 35px 25px 45px;
  `}

  ${Media.desktop`
    padding: 35px 35px 45px;
    min-height: 480px;
  `}
`;

export const Headline = styled.h3`
  font-size: 10px;
  font-weight: ${FontWeight.Bold};
  line-height: 20px;
  color: ${Color.deepGray};
  margin: 5px 0 22px 5px;
  text-transform: uppercase;
`;

export const Activities = styled.div``;

export const Activity = styled.div`
  font-size: 13px;
  line-height: 22px;
  color: ${Color.deepGray};
  display: flex;
  padding: 15px 0 15px 5px;
  overflow: hidden;

  ${Media.tablet`
    align-items: center;
    padding: 18px 0 18px 5px;
  `};

  & + & {
    border-top: 1px solid ${Color.gray8};
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

export const Avatar = styled(AvatarBase).attrs(() => ({
  type: AvatarType.Small,
}))`
  flex: none;
`;

export const Wrapper = styled.div`
  display: flex;
  padding-left: 12px;
  flex-direction: column;
  justify-content: space-between;

  ${Media.tablet`
    width: 100%;
    flex-direction: row;
    align-items: center;
  `}
`;

export const Content = styled.p`
  line-height: 20px;

  ${Media.tablet`
    line-height: 22px;
    align-items: center;
  `}
`;

export const Message = styled.span`
  color: ${Color.gray4};
  word-break: break-word;
  margin: 0 3px;
`;

export const Author = styled.span`
  font-weight: ${FontWeight.Bold};
  color: ${() => Color.brandBasic};
`;

export const Link = styled(LinkBase)`
  text-decoration: none;
  color: inherit;

  ${Media.tablet`
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  `};

  &:hover {
    text-decoration: underline;
  }
`;

export const Date = styled.span`
  width: 120px;
  flex: none;
  font-size: 12px;
  color: ${Color.gray1};
  margin-top: 3px;

  ${Media.tablet`
    margin-top: 0;
    text-align: right;
  `};
`;

export const EmptyStateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 5px;
  color: ${Color.deepGray};
  font-size: 14px;
  text-align: center;
`;

export const EmptyStateTitle = styled.div`
  font-weight: ${FontWeight.Bold};
  margin: 45px 0 1px;
  font-size: 14px;
  line-height: 22px;
`;

export const EmptyStateDescription = styled.div`
  font-weight: ${FontWeight.Light};
  font-size: 14px;
  line-height: 22px;

  ${Link} {
    color: ${() => Color.brandBasic};
    font-weight: ${FontWeight.Normal};
  }
`;

export const Loader = styled(LoaderBase)`
  ${Media.desktop`
    margin-top: 160px;
  `}
`;
