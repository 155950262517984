import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Loader } from "../../../../components/loader/loader.component";
import { GamificationActions } from "../../redux/gamification.reducer";
import { selectPointsHistory } from "../../redux/gamification.selectors";
import { Pages } from "../pages/pages.component";

import { PointsItem } from "./pointsItem/pointsItem.component";
import {
  Container,
  Wrapper,
  SectionHeader,
  BackButton,
  Placeholder,
  PlaceholderText,
  Title,
  Text,
  PlaceholderImage,
} from "./pointsHistory.styled";

export const PointsHistory = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const pointsHistory = useSelector(selectPointsHistory);

  const getPointsHistory = (page) => {
    dispatch(GamificationActions.getPointsHistory(page));
  };

  useEffect(() => {
    getPointsHistory(1);
  }, [dispatch]);

  const fetchPrevPage = () => {
    getPointsHistory(pointsHistory.page - 1);
  };

  const fetchNextPage = () => {
    getPointsHistory(pointsHistory.page + 1);
  };

  if (!pointsHistory) {
    return (
      <Container>
        <Wrapper>
          <Loader />
        </Wrapper>
      </Container>
    );
  }

  return (
    <Container>
      <Wrapper>
        <BackButton defaultPath="/gamification" />
        <SectionHeader>{t("gamification.pointsBox.history")}</SectionHeader>
        {pointsHistory?.data.length ? (
          <>
            {pointsHistory.data.map((pointsItem) => (
              <PointsItem
                key={pointsItem.challenge.id}
                title={pointsItem.challenge.label}
                type={pointsItem.challenge.type.enum}
                timestamp={pointsItem.createdAt}
                points={pointsItem.points}
                comment={pointsItem.comment}
              />
            ))}
            <Pages
              currentPage={pointsHistory.page}
              maxPage={pointsHistory.maxPage}
              onPrevClick={fetchPrevPage}
              onNextClick={fetchNextPage}
              onPageClick={getPointsHistory}
            />
          </>
        ) : (
          <Placeholder>
            <PlaceholderText>
              <Title>{t("gamification.pointsBox.emptyList")}</Title>
              <Text>{t("gamification.pointsBox.placeholderText")}</Text>
            </PlaceholderText>
            <PlaceholderImage />
          </Placeholder>
        )}
      </Wrapper>
    </Container>
  );
};
