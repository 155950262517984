import React from "react";

import {
  TextContainer,
  Text,
  TextPart,
} from "../regulations.styled";

export const TrainingRegulationsContent = () => (
  <TextContainer>
    <TextPart>
      <Text>
        Miejsce na regulamin szkoleń
      </Text>
    </TextPart>
  </TextContainer>
);
