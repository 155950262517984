import React, { Component, useEffect } from "react";
import i18n from "i18next";
import { connect } from "react-redux";
import styled from "styled-components";
import MediaQuery from "react-responsive";
import { withTranslation } from "react-i18next";
import { test } from "ramda";
import { Detector } from "react-detect-offline";
import ReCAPTCHA from "react-google-recaptcha";

import { Regulations } from "../components/regulations/regulations.component";
import { RegulationsType } from "../components/regulations/regulations.constants";
import { BrandSelector } from "../appSettings/brandSelector/brandSelector.component";
import { AppSettingsActions } from "../appSettings/redux/appSettings.reducer";
import { actionCreators as actionCreatorsError } from "../app/networkErrorModal/actions";
import { Input, LoadingWrapper, Button as ButtonBase } from "../UIElements";
import { Register } from "../userRegistration";
import { breakpoints, colors } from "../UIGlobals";
import Password from "../userRegistration/passwordReset";
import { PasswordSuccess } from "../userRegistration/password";
import LogoPattern from "./circlePattern.svg";
import { AppConfig, Theme } from "../appConfig";
import { clearApiCache } from "../helpers/clearApiCache";

import BackgroundImage from "./images/loginBackground.png";
import { UserContextActions } from "./redux/userContext.reducer";

const Button = styled(ButtonBase)`
  &:disabled {
    background-color: ${colors().dirtyWhite};
    &:hover {
      cursor: default;
    }
  }
`;

const Wrapper = styled.div`
  width: 66.6%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (${breakpoints().maxL}) {
    padding: 100px 0 50px;
  }
  @media (${breakpoints().maxS}) {
    width: 100%;
    max-width: 100%;
    margin: 30px 25px;
  }
`;

const Header = styled.div`
  font-size: 2.2rem;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.48;
  letter-spacing: normal;
  text-align: left;
  color: ${colors().black};
  margin-bottom: 50px;
  margin-top: 25px;
  max-width: 380px;
`;

const MoreAction = styled.div`
  font-size: 1.3rem;
  line-height: 1.54;
  text-align: center;
  display: block;
  margin-top: 30px;
  color: ${colors().gray2};
`;

const Link = styled.a`
  display: inline-block;
  color: #adadad;
  text-decoration: none;
  margin-right: 15px;
`;

const Error = styled.span`
  font-size: 1.3rem;
  line-height: 1.54;
  text-align: center;
  color: red;
  display: block;
  margin-bottom: 30px;
  max-width: 380px;
`;

const ErrorLink = styled.a`
  color: red;
`;

const InputWrapper = styled.div`
  margin-bottom: 45px;
  max-width: 380px;
`;

const MiddleWrapper = styled.div`
  @media (${breakpoints().maxS}) {
    padding-top: 60px;
  }
`;

const LogoFull = styled.img`
  margin-top: 50px;
`;

const ImageColumn = styled.div`
  width: 33.3%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors().almostWhite};
	overflow: hidden;
`;

const Image = styled.img`
  max-width: 85%;
  height: auto;
`;

const WSComponent = ({ action }) => {
  action();
  return <div />;
};

const OneBrandComponent = ({ action, brands }) => {
  const currentBrand = brands.length ? brands[0].id : 1;

  useEffect(() => {
    action(currentBrand);
  }, [action, currentBrand]);

  return <div />;
}

class Login extends Component {
  constructor() {
    super();
    this.state = {
      login: "",
      password: "",
      recaptchaResponse: "",
      register: false,
      passwordRemind: false,
      passwordReset: false,
      activate: false,
      timestamp: null
    };
    this.recaptchaRef = React.createRef();
  }

  inputChange(field, event) {
    const changed = {};
    changed[field] = event.target.value;
    this.setState({ ...this.state, ...changed });
  }

  onCaptchaChange(recaptchaResponse) {
    this.setState({ ...this.state, recaptchaResponse });
  }

  insertLinkInError(error) {
    if (!error) return null;
    const errorContent = error.split("{link}");
    return error.indexOf("{link}") === -1
      ? error
      : (
        <>
          {errorContent[0]}
          <ErrorLink href="#przypomnijhaslo">{i18n.t("userContext.login.remindPassword")}</ErrorLink>
          {errorContent[1]}
        </>
      )
  }

  checkHash() {
    let hash = window.location.hash;
    if (test(/#rejestracja/, hash)) {
      this.setState({ ...this.state, passwordRemind: false, register: true, passwordReset: false, activate: false });
    } else if (hash === "#zmienhaslo") {
      this.setState({ ...this.state, passwordRemind: false, passwordReset: true, register: false, activate: false });
    } else if (hash === "#aktywacja") {
      this.setState({ ...this.state, passwordRemind: false, passwordReset: false, register: false, activate: true });
    } else if (hash === "#przypomnijhaslo") {
      this.setState({ ...this.state, passwordRemind: true, passwordReset: true, register: false, activate: false });
    } else {
      this.setState({ ...this.state, register: false, passwordRemind: false, passwordReset: false });
    }
  }

  componentDidMount() {
    this.checkHash();
    const that = this;
    window.addEventListener("hashchange", this.checkHash.bind(that));
    this.props.setLoading(false);
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.state.loggedIn && this.props.state.loggedIn) {
      this.setState({
        ...this.state,
        login: "",
        password: "",
        recaptchaResponse: "",
      });
    }

    if (!this.props.state.loggedIn && prevProps.state.loading && !this.props.state.loading) {
      this.recaptchaRef?.current?.reset();
    }
  }

  componentWillUnmount() {
    const that = this;
    window.removeEventListener("hashchange", this.checkHash.bind(that));
  }

  UNSAFE_componentWillMount() {
    const { state } = this.props;
    if(state.data && (this.state.timestamp === null)) state.data.message = "";
    if(state.data && (this.state.timestamp !== state.data.timestamp)) this.setState({timestamp: state.data.timestamp});
  }

  showRegistration() {
    this.setState({ ...this.state, register: true });
  }

  trySubmit = (e, online) => {
    if (!online || !this.state.password.length || !this.state.login.length) return;

    const { errorHandler, loginAction } = this.props;
    errorHandler(false);

    if (e.keyCode === 13 && e.shiftKey === false) {
      clearApiCache();
      loginAction(this.state.login, this.state.password);
    }
  }

  onLoginClick(login, password, recaptchaResponse) {
    const { loginAction } = this.props;
    clearApiCache();
    loginAction(login, password, recaptchaResponse);
  }

  render() {
    const {
      login,
      password,
      recaptchaResponse,
      register,
      passwordReset,
      activate,
      passwordRemind,
    } = this.state;
    const { state, brand, tryConnectToWebsocket, selectBrand } = this.props;

    if (!state.loggedIn) {
      if (window.location.pathname === "/polityka-prywatnosci") {
        return <Regulations type={RegulationsType.PrivacyPolicy} />;
      } else if (window.location.pathname === "/klauzula-informacyjna") {
        return <Regulations type={RegulationsType.InfoClause} />;
      } else if (window.location.pathname === "/regulamin") {
        return <Regulations type={RegulationsType.Regulations} />;
      } else if (window.location.pathname === "/regulamin-szkolen") {
        return <Regulations type={RegulationsType.TrainingRegulations} />;
      }
    }

    return register && !state.loggedIn ? (
      <Register />
    ) : (passwordReset || activate) && !state.loggedIn ? (
      state.success
        ? <PasswordSuccess />
        : <Password
          activate={activate}
          passwordRemind={passwordRemind}
        />
    ) : (
      <>
        {!state.loggedIn && (
          <Detector
            polling={{ enabled: false }}
            render={({ online }) => (
              <>
                <Wrapper>
                  <MiddleWrapper>
                    <MediaQuery query={`(${breakpoints().maxL})`}>
                      <img src={LogoPattern} alt="logo" />
                    </MediaQuery>
                    <LogoFull src="/static/logoFull.svg" width="154px" height="22px" />
                    <Header>
                      {i18n.t("userContext.login.loginHeader", {
                        platformName: AppConfig.platformName
                      })}
                    </Header>
                    <InputWrapper>
                      <Input
                        label={i18n.t("userContext.login.login")}
                        value={login}
                        name={"login"}
                        onChange={event => this.inputChange("login", event)}
                        placeholder={""}
                        onKeyDown={event => this.trySubmit(event, online)}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Input
                        label={i18n.t("userContext.login.password")}
                        value={password}
                        name={"password"}
                        onChange={event => this.inputChange("password", event)}
                        type={"password"}
                        placeholder={""}
                        onKeyDown={event => this.trySubmit(event, online)}
                      />
                    </InputWrapper>
                    {Theme.AdvancedPasswordActive && AppConfig.reCaptchaEnabled && (
                      <ReCAPTCHA
                        sitekey={AppConfig.reCaptchaKey}
                        onChange={(response) => this.onCaptchaChange(response)}
                        ref={this.recaptchaRef}
                      />
                    )}
                    {state.data && !state.data.success &&
                      <Error>{this.insertLinkInError(state.data.message)}</Error>
                    }
                    {!online && (
                      <Error>{i18n.t("userContext.login.connectionError")}</Error>
                    )}
                    <div>
                      <LoadingWrapper isLoading={state.loading}>
                        <Button
                          onClick={() => this.onLoginClick(login, password, recaptchaResponse)}
                          width={380}
                          height={58}
                          $background={"platformPrimary"}
                          size={1.4}
                          type={"submit"}
                          disabled={
                            !login
                            || !password
                            || (Theme.AdvancedPasswordActive && AppConfig.reCaptchaEnabled && !recaptchaResponse)
                            || !online
                          }
                        >
                          { i18n.t("userContext.login.signIn") }
                        </Button>
                      </LoadingWrapper>
                    </div>
                    <MoreAction>
                      <Link href="#przypomnijhaslo">{ i18n.t("userContext.login.remindPassword") }</Link>
                      <Link href="#rejestracja">{ i18n.t("userContext.login.signUp") }</Link>
                      <Link href={`mailto:${AppConfig.platformContactEmail}`}>{ i18n.t("userContext.login.help") }</Link>
                    </MoreAction>
                  </MiddleWrapper>
                </Wrapper>
                <MediaQuery query={`(${breakpoints().minL})`}>
                  <ImageColumn>
                    <Image src={BackgroundImage} />
                  </ImageColumn>
                </MediaQuery>
              </>
            )}/>
        )}
        {state.loggedIn && !brand && (
          state.data.user.brands.length <= 1 ? (
            <OneBrandComponent action={selectBrand} brands={state.data.user.brands} />
          ) : (
            <BrandSelector brands={state.data.user.brands.length} />
          )
        )}
        {state.loggedIn && <WSComponent action={tryConnectToWebsocket} />}
      </>
    );
  }
}
const mapStateToProps = state => ({ state: state.userContext, brand: state.appSettings.currentBrand });

const mapDispatchToProps = dispatch => ({
  loginAction: (login, password, recaptchaResponse) => dispatch(UserContextActions.tryLogin(login, password, recaptchaResponse)),
  setLoading: (loading) => dispatch(UserContextActions.setLoading(loading)),
  tryConnectToWebsocket: () => dispatch(UserContextActions.tryConnectWebsocket()),
  selectBrand: (id) => dispatch(AppSettingsActions.selectBrand(id)),
  errorHandler: () => dispatch(actionCreatorsError.errorHandler(false))
});

export default withTranslation()(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login));
