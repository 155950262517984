import React from "react";
import Modal from "react-modal";
import styled from "styled-components";
import { connect } from "react-redux";
import i18n from "i18next";

import { actionCreators } from "./actions";
import { colors, breakpoints } from "../../UIGlobals";
import { Button, Icon } from "../../UIElements";
import * as UI from "../../userRegistration/registerUI";
import { OfflineImg } from "./offline";

const styles = {
  overlay: {
    background: "rgba(0, 0, 0, .8)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 999,
  },
  content: {
    maxWidth: "90vh",
    maxHeight: "90vh",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: colors().white,
    borderRadius: 0,
    border: "none",
    textAlign: "center",
    padding: 0,
    margin: "auto"
  }
};

const OfflineTextHeader = styled.h5`
  color: #4F5659;
  font-size: 11px;
  font-weight: 800;
  line-height: 46px;
  padding-top: 100px;
  text-transform: uppercase;
  @media (${breakpoints().maxL}) {
    padding-top: 10px;
  }
`;

const OfflineText = styled.p`
  color: #333333;
  font-size: 32px;
  font-weight: 300;
  letter-spacing: 0.5px;
  width: 529px;
  padding-bottom: 30px;
  line-height: 46px;
  @media (${breakpoints().maxL}) {
    width: 300px;
    font-size: 20px;
    line-height: 35px;
  }
`;

const ConfirmButton = styled(Button)`
  width: 529px;
  margin-bottom: 40px;
  @media (${breakpoints().maxL}) {
    width: 300px;
    margin-top: 10px;
  }
`;

const ModalContent = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

const NetworkErrorModal = ({isOpen, ...props}) => {
  const closeModal = () => {
    props.errorHandler(false);
    window.location.href = "/";
  }

  return (
    <Modal
      ariaHideApp={false}
      isOpen={isOpen}
      style={styles}
    >
      <ModalContent>
        <UI.HeaderSVG>
          <Icon icon="platform_owner_logo_full" size="140" color={colors().black}/>
        </UI.HeaderSVG>
        <OfflineTextHeader>{i18n.t("app.networkErrorModal.youAreOffline")}</OfflineTextHeader>
        <OfflineText>{i18n.t("app.networkErrorModal.connectToInternet")}</OfflineText>
        <OfflineImg />
        <ConfirmButton onClick={closeModal}>
          {i18n.t("app.networkErrorModal.close")}
        </ConfirmButton>
      </ModalContent>
    </Modal>
  )
}

const mapStateToProps = (state) => ({ user: state.userContext });

const mapDispatchToProps = dispatch => ({ errorHandler: () => dispatch(actionCreators.errorHandler(false)) });

export default connect(mapStateToProps, mapDispatchToProps)(NetworkErrorModal);
