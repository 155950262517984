import styled, { css } from "styled-components";
import { propEq } from "ramda";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { ReactComponent as MedalSvgBase } from "../../images/medal.svg";
import { ReactComponent as ClockSvgBase } from "../../images/clock.svg";
import { ReactComponent as ColumnGraphBase } from "../../images/columnGraph.svg";
import { styleWhenTrue } from "../../helpers/rendering";
import { Color } from "../../theme/colors";
import { FontWeight } from "../../theme/fonts";

import { ChartColorType } from "./progressChart.constants";

export const Container = styled.div`
  width: 100%;
  background-color: ${Color.white};
  padding: 53px 0 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:first-of-type {
    margin-left: 0;
  }
`;

export const ChartWrapper = styled.div`
  width: 156px;
  position: relative;
`;

export const Chart = styled(CircularProgressbarWithChildren)`
  transform: rotate(225deg);
  transform-origin: center center;

  && {
    .CircularProgressbar-path {
      stroke: ${() => Color.brandBasic};
      transition: stroke-dashoffset 1.5s ease 0s;
      stroke-linecap: butt;

      ${styleWhenTrue(propEq(ChartColorType.Gold, "color"), css`
        stroke: ${Color.gold};
      `)}

      ${styleWhenTrue(propEq(ChartColorType.Silver, "color"), css`
        stroke: ${Color.silver};
      `)}

      ${styleWhenTrue(propEq(ChartColorType.Bronze, "color"), css`
        stroke: ${Color.bronze};
      `)}

      ${styleWhenTrue(propEq(ChartColorType.Blue, "color"), css`
        stroke: ${Color.lightBlue};
      `)}
    }

    .CircularProgressbar-trail {
      stroke: ${Color.almostWhite};
      stroke-linecap: butt;
    }
  }
`;

export const Label = styled.p`
  font-weight: ${FontWeight.SemiBold};
  font-size: 13px;
  line-height: 6px;
  color: ${Color.gray9};
`;

export const ValueWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${Color.dirtyWhite};
  border-radius: 50%;
  width: 93px;
  height: 93px;
`;

export const Value = styled.p`
  font-size: 19px;
  line-height: 42px;
  color: ${Color.almostBlack};
  font-weight: ${FontWeight.SemiBold};
`;

export const ChartIconWrapper = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;

  ${styleWhenTrue(propEq(ChartColorType.Blue, "color"), css`
    svg {
      fill: ${Color.lightBlue};
    }
  `)}
`;

export const ClockSvg = styled(ClockSvgBase)`
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid ${Color.dirtyWhite};
  position: relative;
  left: -10px;
`;

export const GoldMedalSvg = styled(MedalSvgBase)`
  width: 14px;
  height: 25px;
  fill: ${Color.gold};
`;

export const ColumnGraphSvg = styled(ColumnGraphBase)`
  width: 16px;
  height: 20px;
`;
