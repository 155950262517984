import { ReactComponent as ModuleIcon } from "../../images/dashboard.svg";

export const Config = {
  moduleName: "dashboard",
  dependencies: [
    "@news/newsActivity",
  ],
  label: "dashboard.pageTitle",
  Icon: ModuleIcon,
  urls: ["/pulpit", "/"],
  routeName: "api_dashboard",
  options: {},
  theme: {
    welcomeMessage: {
      backgroundColor: "",
      hostBodyColor: "",
    }
  }
};
