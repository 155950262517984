import React from "react";
import i18n from "i18next";
import { Component } from "react";
import { withTranslation } from "react-i18next";
import ScrollIntoViewIfNeeded from "react-scroll-into-view-if-needed";
import styled from "styled-components";

import {
  simplePasswordRegex,
  advancedPasswordRegex,
  min12charsRegex,
  capitalLettersRegex,
  decimalRegex,
  specialCharsRegex,
  min4RepeatedCharsRegex,
  disallowedCharsRegex,
} from "../modules/profile/components/passwordsTab/passwordsTab.schema";
import { ButtonVariant } from "../components/button/button.constants";
import {
  ContentWrapper,
  ContentInner,
  Field,
  Input,
} from "../UIElements";
import { colors } from "../UIGlobals";
import * as UI from "./registerUI";
import { AppConfig, Theme } from "../appConfig";
import MainFooter from "../app/mainFooter";
import { EyeButton } from "../components/input/eyeButton/eyeButton.component";
import { Media } from "../theme";

const Error = styled.span`
  font-size: 1.3rem;
  line-height: 1.54;
  text-align: center;
  color: red;
  display: block;
`;

const BackLink = styled.a`
  font-size: 14px;
  background-color: ${colors().deepGray};
  display: grid;
  place-items: center;
  margin: 40px auto 0;
  width: 100%;
  height: 56px;
  transition: all 0.18s cubic-bezier(0.42,0,0.58,0.97);
  color: ${colors().white};
  border-radius: 3px;
  text-decoration: none;

  &:hover {
    opacity: 0.8;
  }

  ${Media.tablet`
    width: 300px;
  `}
`;

class Password extends Component {
  constructor() {
    super();
    this.state = {
      login: "",
      pass1: "",
      pass1Visible: false,
      pass2: "",
      pass2Visible: false,
      error: "",
    };
  }

  onChange = field => event => {
    this.setState({ ...this.state, [field]: event.target.value });
  };

  togglePasswordVisible = (fieldName) => {
    const stateFieldName = `${fieldName}Visible`;
    this.setState({
      ...this.state,
      [stateFieldName]: !this.state[stateFieldName],
    });
  };

  submit = () => {
    const { passwordRemind } = this.props;
    const passwordInfoText = Theme.AdvancedPasswordActive
      ? i18n.t("userRegistration.password.advancedPasswordMinLengthInfo")
      : i18n.t("userRegistration.password.passwordMinLengthInfo");
    const passwordRegex = Theme.AdvancedPasswordActive ? advancedPasswordRegex : simplePasswordRegex;

    if (passwordRemind) {
      if (this.state.login.length < 2) {
        this.setState({error: i18n.t("userRegistration.password.wrongIdInfo")});
      } else {
        this.props.remindPassword(this.state.login);
      }
    } else {
      if (!passwordRegex.test(this.state.pass1) && this.state.pass1 !== this.state.pass2) {
        this.setState({
          error: `${passwordInfoText} ${i18n.t("userRegistration.password.equalPasswordInfo")}`
        });
      } else if (!passwordRegex.test(this.state.pass1)) {
        this.setState({ error: passwordInfoText });
      } else if (this.state.pass1 !== this.state.pass2) {
        this.setState({ error: i18n.t("userRegistration.password.equalPasswordInfo") });
      } else {
        let token;
        if (window.location.search.substr(1).length > 0) {
          token = window.location.search.substr(1).split("=")[1];
          this.props.setPassword(
            token,
            this.state.pass1,
            (errorMessage) => {
              this.setState({ error: errorMessage });
            }
          );
        } else {
          this.setState({ error: i18n.t("userRegistration.password.wrongTokenInfo") });
        }
      }
    }
  };

  render() {
    const { passwordRemind, activate, state } = this.props;

    return state?.data.success
      ? (<PasswordSuccess
        message={state.data.message}
        header={passwordRemind
          ? (i18n.t("userContext.login.remindPassword"))
          : (activate
            ? i18n.t("userRegistration.password.setPasswordActivateAccount")
            : i18n.t("userRegistration.password.changePassword")
          )
        }
      />)
      : (
        <>
          <UI.RegisterContainer>
            <a href="/">
              <UI.HeaderSVG>
                <img src="/static/logoFull.svg" width="115px" height="16px" alt="logo" />
              </UI.HeaderSVG>
            </a>
            <ScrollIntoViewIfNeeded active={!!passwordRemind} options={{
              block: "start"
            }}>
              <UI.TabContainer $newTheme={Theme.NewPasswordRemind}>
                {Theme.NewPasswordRemind ? (
                  <UI.NewHeadline>
                    {passwordRemind
                      ? i18n.t("userContext.login.remindPassword")
                      : activate
                        ? i18n.t("userRegistration.password.setPasswordActivateAccount")
                        : i18n.t("userRegistration.password.changePassword")
                    }
                  </UI.NewHeadline>
                ) : (
                  <UI.Headline fontSize={27} color={colors().deepGray}>
                    {passwordRemind
                      ? i18n.t("userContext.login.remindPassword")
                      : activate
                        ? i18n.t("userRegistration.password.setPasswordActivateAccount")
                        : i18n.t("userRegistration.password.changePassword")
                    }
                  </UI.Headline>
                )}
              </UI.TabContainer>
              <ContentWrapper $minHeight={"calc(100vh - 100px - 188px)"}>
                <ContentInner
                  $minWidth="500px"
                  $maxWidth={Theme.NewPasswordRemind ? "940px" : "500px"}
                  $background="white"
                >
                  <UI.Inner padding={Theme.NewPasswordRemind ? "80px 80px 30px" : "80px 60px"}>
                    {Theme.NewPasswordRemind && passwordRemind && (
                      <UI.Hint>
                        <UI.BoldText>{i18n.t("userRegistration.password.boldHint")}</UI.BoldText>
                        {" "}
                        {i18n.t("userRegistration.password.hint")}
                      </UI.Hint>
                    )}
                    <UI.Row>
                      <UI.Column $flexDirection={"column"}>
                        {passwordRemind &&
                          <>
                            <Field $newTheme={Theme.NewPasswordRemind}>
                              <Input
                                label={
                                  i18n.t("userRegistration.password.login") + (Theme.NewPasswordRemind ? "" : i18n.t("userRegistration.password.id"))
                                }
                                placeholder={""}
                                value={this.state.login}
                                type={"text"}
                                onChange={this.onChange("login")}
                              />
                            </Field>
                            {this.state.error && <Error>{this.state.error}</Error>}
                            {Theme.NewPasswordRemind ? (
                              <UI.Buttons>
                                <UI.NewButton
                                  $large
                                  variant={ButtonVariant.Outlined}
                                  onClick={() => history.back()}
                                >
                                  {i18n.t("global.cancel")}
                                </UI.NewButton>
                                <UI.NewButton $large onClick={this.submit}>
                                  {i18n.t("userContext.login.remindPassword")}
                                </UI.NewButton>
                              </UI.Buttons>
                            ) : (
                              <UI.OldButton $large onClick={this.submit}>
                                {i18n.t("userContext.login.remindPassword")}
                              </UI.OldButton>
                            )}
                          </>
                        }
                        {!passwordRemind &&
                          <>
                            <UI.PasswordField>
                              <Input
                                label={i18n.t("userRegistration.password.newPassword")}
                                placeholder={""}
                                value={this.state.pass1}
                                type={this.state.pass1Visible ? "text" : "password"}
                                onChange={this.onChange("pass1")}
                              />
                              <EyeButton
                                passwordVisible={this.state.pass1Visible}
                                togglePasswordVisibility={() => this.togglePasswordVisible("pass1")}
                              />
                            </UI.PasswordField>
                            {Theme.AdvancedPasswordActive && (
                              <>
                                <UI.ValidationHint>
                                  <UI.ValidationText>
                                    <UI.BoldText>{i18n.t("profile.passwordsTab.info")}</UI.BoldText>
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {this.state.pass1.match(min12charsRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.minChars")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {this.state.pass1.match(capitalLettersRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.capitalLetters")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {this.state.pass1.match(decimalRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.decimals")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {this.state.pass1.match(specialCharsRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.specialChars")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {!this.state.pass1.match(min4RepeatedCharsRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.maxRepeatedChars")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                                <UI.ValidationHint>
                                  {!this.state.pass1.match(disallowedCharsRegex) ? <UI.CheckIcon /> : <UI.ErrorIcon />}
                                  <UI.ValidationText>
                                    {i18n.t("profile.passwordsTab.disallowedChars")}
                                  </UI.ValidationText>
                                </UI.ValidationHint>
                              </>
                            )}
                            <UI.PasswordField>
                              <Input
                                label={i18n.t("userRegistration.password.rewritePassword")}
                                placeholder={""}
                                type={this.state.pass2Visible ? "text" : "password"}
                                value={this.state.pass2}
                                onChange={this.onChange("pass2")}
                              />
                              <EyeButton
                                passwordVisible={this.state.pass2Visible}
                                togglePasswordVisibility={() => this.togglePasswordVisible("pass2")}
                              />
                            </UI.PasswordField>
                            {this.state.error && <Error>{this.state.error}</Error>}
                            {Theme.NewPasswordRemind ? (
                              <UI.Buttons>
                                <UI.NewButton $large onClick={this.submit}>
                                  {i18n.t("userRegistration.password.change")}
                                </UI.NewButton>
                              </UI.Buttons>
                            ) : (
                              <UI.OldButton $large onClick={this.submit}>
                                {i18n.t("userRegistration.password.change")}
                              </UI.OldButton>
                            )}
                          </>
                        }
                      </UI.Column>
                    </UI.Row>
                  </UI.Inner>
                </ContentInner>
              </ContentWrapper>
              <MainFooter />
            </ScrollIntoViewIfNeeded>
          </UI.RegisterContainer>
        </>
      );
  }
}

class RegistrationConfirm extends Component {
  render() {
    let login = this.props.login;
    return (
      <>
        <UI.RegisterContainer>
          <a href="/">
            <UI.HeaderSVG>
              <img src="/static/logoFull.svg" width="115px" height="16px" alt="logo" />
            </UI.HeaderSVG>
          </a>
          <UI.TabContainer>
            <UI.Headline fontSize={27} color={colors().deepGray}>
              { i18n.t("userRegistration.password.registration") }
            </UI.Headline>
          </UI.TabContainer>
          <ContentWrapper $minHeight={"calc(100vh - 100px - 188px)"}>
            <ContentInner width={"500px"} $minWidth={"500px"} $background={"white"}>
              <UI.Inner padding={"80px 60px"}>
                <UI.Row>
                  <UI.Column $flexDirection={"column"}>
                    <UI.Text lineHeight={"3rem"}>
                      {login.substr(0,3).toLowerCase() === "dkx" &&
                        <>
                          { i18n.t("userRegistration.password.registrationThankYouMsg_1") + " "}
                        </>
                      }
                      {login.substr(0,3).toLowerCase() !== "dkx" && !!login.length &&
                        <>
                          {`${i18n.t("userRegistration.password.registrationThankYouMsg_2", {platformContactEmail: AppConfig.platformContactEmail})} `}
                        </>
                      }
                      {!login.length && (
                        <>
                          {i18n.t("userRegistration.password.registrationConfirmationMsg") + " "}
                        </>
                      )}
                    </UI.Text>
                  </UI.Column>
                </UI.Row>
                <BackLink href="/">
                  { i18n.t("userRegistration.password.comeBack") }
                </BackLink>
              </UI.Inner>
            </ContentInner>
          </ContentWrapper>
        </UI.RegisterContainer>
      </>
    );
  }
}

class PasswordSuccess extends Component {
  render() {
    return (
      <>
        <UI.RegisterContainer>
          <a href="/">
            <UI.HeaderSVG>
              <img src="/static/logoFull.svg" width="115px" height="16px" alt="logo" />
            </UI.HeaderSVG>
          </a>
          <UI.TabContainer>
            {Theme.NewPasswordRemind ? (
              <UI.NewHeadline>{this.props.header}</UI.NewHeadline>
            ) : (
              <UI.Headline fontSize={27} color={colors().deepGray}>
                {this.props.header}
              </UI.Headline>
            )}
          </UI.TabContainer>
          <ContentWrapper $minHeight={"calc(100vh - 100px - 188px)"}>
            <ContentInner
              $minWidth="500px"
              $maxWidth={Theme.NewPasswordRemind ? "940px" : "500px"}
              $background="white"
            >
              <UI.Inner padding={"80px 60px"}>
                <UI.Row>
                  <UI.Column $flexDirection={"column"}>
                    <UI.Text>
                      {this.props.message ? this.props.message + " " : i18n.t("userRegistration.password.operationSucceed")}
                    </UI.Text>
                  </UI.Column>
                </UI.Row>
                <BackLink href="/">
                  { i18n.t("userRegistration.password.comeBack") }
                </BackLink>
              </UI.Inner>
            </ContentInner>
          </ContentWrapper>
        </UI.RegisterContainer>
      </>
    );
  }
}

const PasswordWrapper = withTranslation()(Password);
const RegistrationConfirmWrapper = withTranslation()(RegistrationConfirm);
const PasswordSuccessWrapper = withTranslation()(PasswordSuccess);

export {
  PasswordWrapper as Password,
  RegistrationConfirmWrapper as RegistrationConfirm,
  PasswordSuccessWrapper as PasswordSuccess,
};
