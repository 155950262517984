import React from "react";

import {
  TextContainer,
  Text,
  TextPart,
} from "../regulations.styled";

export const RegulationsContent = () => (
  <TextContainer>
    <TextPart>
      <Text>
        Miejsce na regulamin serwisu
      </Text>
    </TextPart>
  </TextContainer>
);
