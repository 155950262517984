import React from "react";
import {
  TextContainer,
  TextPart,
  Text,
} from "../regulations.styled";

export const InfoClauseContent = () => (
  <TextContainer>
    <TextPart>
      <Text>Miejsce na Klauzulę Informacyjną serwisu</Text>
    </TextPart>
  </TextContainer>
);
