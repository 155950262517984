import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { cond, equals, always } from "ramda";

import { renderWhenTrue } from "../../../../helpers/rendering";
import { timestampToDate } from "../../../../helpers/date";
import { useModuleConfig, ModuleType } from "../../../";
import { selectNewsLatestActivities, selectAreActivitiesPending } from "../../redux/news.selectors";
import { NewsActions } from "../../redux/news.reducer";
import { ReactComponent as EmptyActivitiesSVG } from "../../images/emptyActivities.svg";

import { ActivityType } from "./newsActivity.constants";
import {
  Container,
  Avatar,
  Headline,
  Activities,
  Activity,
  Date,
  Message,
  Link,
  Author,
  Wrapper,
  Content,
  EmptyStateContainer,
  EmptyStateTitle,
  EmptyStateDescription,
  Loader,
} from "./newsActivity.styled";

export const NewsActivity = ({ className }) => {
  const { t } = useTranslation();
  const latestActivities = useSelector(selectNewsLatestActivities);
  const isPending = useSelector(selectAreActivitiesPending);
  const dispatch = useDispatch();
  const config = useModuleConfig(ModuleType.News);

  useEffect(() => {
    dispatch(NewsActions.getLatestActivities());
  }, [dispatch]);

  const getMessage = cond([
    [equals(ActivityType.NewComment), always(t("news.newsActivity.newCommentMessage"))],
    [equals(ActivityType.NewLike), always(t("news.newsActivity.newLikeMessage"))],
    [equals(ActivityType.NewNews), always(t("news.newsActivity.newNewsMessage"))],
  ]);

  const renderActivity = ({ id, publisher, data, type }) => {
    const author = `${publisher.firstName} ${publisher.lastName}`;
    const date = timestampToDate(data.timestamp);
    const link = `${config.urls[1]}${config.routes.entry.replace(":id", data.id)}`;

    return (
      <Activity key={id}>
        <Avatar
          name={author}
          src={publisher.avatar}
          dark={publisher.isCurrentUser.toString()}
        />
        <Wrapper>
          <Content>
            <Author>{author}</Author>
            <Message>{getMessage(type)}</Message>
            <Link to={link}>{data.title}</Link>
          </Content>
          <Date>{date}</Date>
        </Wrapper>
      </Activity>
    );
  };

  const renderActivities = renderWhenTrue(() => (
    <Activities>
      {latestActivities.map(renderActivity)}
    </Activities>
  ));

  const renderEmptyState = renderWhenTrue(() => (
    <EmptyStateContainer>
      <EmptyActivitiesSVG />
      <EmptyStateTitle>{t("news.newsActivity.emptyTitle")}</EmptyStateTitle>
      <EmptyStateDescription>
        {t("news.newsActivity.emptyDescription")}
        {" "}
        <Link to={config.urls[0]}>{t("news.newsActivity.title")}</Link>
      </EmptyStateDescription>
    </EmptyStateContainer>
  ));

  const renderLoader = renderWhenTrue(() => (
    <Loader />
  ));

  return (
    <Container className={className}>
      <Headline>{t("news.newsActivity.title")}</Headline>
      {renderLoader(isPending)}
      {renderActivities(!isPending && !!latestActivities.length)}
      {renderEmptyState(!isPending && !latestActivities.length)}
    </Container>
  );
};


NewsActivity.propTypes = {
  className: PropTypes.string,
};
