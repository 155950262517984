// THIS FILE IS GENERATED AUTOMATICALLY

import dashboard from "./dashboard";
import news from "./news";
import knowledgeBase from "./knowledgeBase";
import trainings from "./trainings";
import training from "./training";
import onboarding from "./onboarding";
import videoPlayer from "./videoPlayer";
import notifications from "./notifications";
import competence from "./competence";
import surveys from "./surveys";
import teamIndicators from "./teamIndicators";
import gamification from "./gamification";
import certificates from "./certificates";
import profile from "./profile";
import webinars from "./webinars";
import scrollLesson from "./scrollLesson";
import anonSurvey from "./anonSurvey";
import form from "./form";
import search from "./search";
import { NewsActivity } from "./news/components/newsActivity/newsActivity.component.js";
import { ProfileCompetenceList } from "./competence/components/profileCompetenceList/profileCompetenceList.component.js";
import { VideoSection } from "./videoPlayer/components/videoSection/videoSection.component.js";

export const Modules = {
  dashboard,
  news,
  knowledgeBase,
  trainings,
  training,
  onboarding,
  videoPlayer,
  notifications,
  competence,
  surveys,
  teamIndicators,
  gamification,
  certificates,
  profile,
  webinars,
  scrollLesson,
  anonSurvey,
  form,
  search,
};

export const ModuleType = {
  Dashboard: "dashboard",
  News: "news",
  KnowledgeBase: "knowledgeBase",
  Trainings: "trainings",
  Training: "training",
  Onboarding: "onboarding",
  VideoPlayer: "videoPlayer",
  Notifications: "notifications",
  Competence: "competence",
  Surveys: "surveys",
  TeamIndicators: "teamIndicators",
  Gamification: "gamification",
  Certificates: "certificates",
  Profile: "profile",
  Webinars: "webinars",
  ScrollLesson: "scrollLesson",
  AnonSurvey: "anonSurvey",
  Form: "form",
  Search: "search",
};

export const Widgets = {
  NewsActivity,
  ProfileCompetenceList,
  VideoSection,
};

export const WidgetType = {
  NewsActivity: "NewsActivity",
  ProfileCompetenceList: "ProfileCompetenceList",
  VideoSection: "VideoSection",
};
