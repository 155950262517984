import styled from "styled-components";

import { breakpoints, transition, colors } from "../../UIGlobals";

const QuestionTitle = styled.h1`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: normal;
  text-align: left;
  color: #343434;
  margin: 5.5rem 0 7rem 0;
  display: flex;
  align-items: center;
  img {
    margin: 0 0 0 15px;
    flex-shrink: 0;
  }
  @media (${breakpoints().maxM}) {
    flex-direction: column;
    img {
      margin: 15px 0 0 0;
    }
  }
`;

const QuestionAnswer = styled.div`
  font-size: 1.4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  line-height: 1.43;
  color: ${props => (props.selected ? colors().black : colors().deepGray)};
  background: ${props =>
  props.error ? colors().lightPink
    : props.selected ? (props.preview ? (props.correct ? colors().veryLightGreen : colors().lightPink) : "#e4e5e9") : colors().almostWhite};
  padding: 44px 80px 41px 80px;
  margin-bottom: 2px;
  width: 100%;
  cursor: ${props => (props.preview ? "default" : "pointer")};
  position: relative;
  transition: ${transition().custom};
  border: 1px solid ${props => props.selected ? colors().dirtyWhite : "transparent"}
  &:hover {
    opacity: ${props => (props.selected && !props.preview ? 1 : 0.7)};
  }
  &:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    background: ${props => (props.selected ? colors().black : colors().white)};
    border: 1px solid ${props => (props.selected ? colors().black : colors().dirtyWhite)};
    position: absolute;
    left: 25px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: ${props => (props.multi ? "" : "16px")};
  }
  &:after {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background: ${colors().white};
    opacity: ${props => (props.selected ? 1 : 0)};
    position: absolute;
    left: 31px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: ${props => (props.multi ? "" : "16px")};
  }
  i {
    position: absolute;
    right: 55px;
    top: 47px;
  }
`;

const QuestionButtons = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 4rem 0;

  @media (${breakpoints().maxM}) {
    width: 100%;
  }
`;

export {
  QuestionTitle,
  QuestionAnswer,
  QuestionButtons,
};
