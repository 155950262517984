import styled from "styled-components";

import { Color, ContentMaxWidth, FontWeight, Media } from "../../theme";

export const Container = styled.div`
  padding: 54px 20px;
  width: 100%;
  background-color: ${Color.almostWhite};
  display: flex;
  justify-content: center;

  ${Media.tablet`
    padding: 54px 80px;
  `}

  ${Media.desktop`
    padding: 54px 160px;
  `}
`;

export const Wrapper = styled.div`
  max-width: ${ContentMaxWidth};
  width: 100%;
`;

export const TextContainer = styled.div`
  max-width: 660px;
`;

export const Header = styled.p`
  font-size: 13px;
  line-height: 18px;
  font-weight: ${FontWeight.Bold};
`;

export const Text = styled.p`
font-size: 13px;
line-height: 18px;
`;

export const TextPart = styled.div`
  margin-top: 10px;
`;

export const List = styled.ol`
  list-style-type: decimal;
  font-size: 13px;
  line-height: 18px;
  padding-left: 14px;
`;

export const ListElement = styled.li``;

export const Table = styled.table`
  border: 1px solid ${Color.black};
  border-collapse: collapse;
`;

export const Th = styled.th`
  border: 1px solid ${Color.black};
  padding: 2px 5px;
`;

export const Td = styled.td`
  border: 1px solid ${Color.black};
  padding: 2px 5px;
`;
