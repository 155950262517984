import { CompetenceType } from "./modules/competence/components/competenceDetails/competenceDetails.constants";
import { StatisticsTheme } from "./modules/teamIndicators/components/statistics/statistics.constants";
import { DisabledItemTheme, GamificationTheme } from "./app/mainNavigation/components/navItem.constants";
import { SidebarTheme } from "./userContext/loginWrapper/loginWrapper.constants";
import { AvatarTheme } from "./components/avatar/avatar.constants";
import { TagFilteringTheme } from "./components/competenceTagFiltersList/competenceTagFiltersList.constants";
import { IDDFilterTheme } from "./modules/knowledgeBase/components/filters/filters.constants";
import { CareerTheme } from "./modules/profile/profile.constants";
import { CookieName } from "./app/cookiePopup/cookiePopup.constants";
import { BrandTheme } from "./appSettings/brandReset/brandReset.constants";
import { Module } from "./modules/modules.constants";
import { TrainingPhoneLength } from "./modules/trainings/trainings.constants";
import { ProfileEditTheme } from "./modules/profile/components/editForm/editForm.constants";
import { ExternalRegistration } from "./userRegistration/register.constants";
import { OnboardingAllMode } from "./modules/onboarding/onboarding.constants";
import { TutorialStatus } from "./components/tutorialPopup/tutorialPopup.constants";
import { LanguageTheme } from "./translations/i18n.constants";
import { Color } from "./theme/colors";
import { isCookieEnabled } from "./helpers/isCookieEnabled";

export const locationObject = (typeof window !== "undefined") ? window.location : {};

const Domain = locationObject.hostname ? locationObject.hostname.replace("www.", "") : "";

const nodeDomain = (typeof window !== "undefined") ? window.NODE_API_DOMAIN : "";

export const AppConfig = {
  nodeApi: process.env.REACT_APP_NODE_API
    ? process.env.REACT_APP_NODE_API
    : nodeDomain !== ""
      ? `${locationObject.protocol}//${nodeDomain}/api`
      : `${locationObject.protocol}//node.${Domain}/api`,
  api: process.env.REACT_APP_PHP_API
    ? process.env.REACT_APP_PHP_API
    : locationObject.origin,
  content: process.env.REACT_APP_CONTENT
    ? process.env.REACT_APP_CONTENT
    : locationObject.origin,
  webservice: process.env.REACT_APP_WEBSERVICE
    ? process.env.REACT_APP_WEBSERVICE
    : nodeDomain !== ""
      ? `wss://${nodeDomain}`
      : `wss://node.${Domain}`,
  encryptMode: process.env.REACT_APP_ENCRYPT_MODE
    ? process.env.REACT_APP_ENCRYPT_MODE
    : true,
  platformContactEmail: process.env.REACT_APP_PLATFORM_CONTACT_EMAIL
    ? process.env.REACT_APP_PLATFORM_CONTACT_EMAIL
    : "contact@competence.zone",
  platformName: process.env.REACT_APP_PLATFORM_NAME
    ? process.env.REACT_APP_PLATFORM_NAME
    : "MUG learning platform",
  GA_id: (process.env.REACT_APP_GA_ID && isCookieEnabled(CookieName.Analytical))
    ? process.env.REACT_APP_GA_ID
    : (typeof window !== "undefined" && isCookieEnabled(CookieName.Analytical)) ? window.GA_ID : "",
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY
    ? process.env.REACT_APP_RECAPTCHA_KEY
    : (typeof window !== "undefined") ? window.RECAPTCHA_KEY : "",
  reCaptchaEnabled: process.env.REACT_APP_RECAPTCHA_ENABLED === "true"
    ? process.env.REACT_APP_RECAPTCHA_ENABLED
    : (typeof window !== "undefined") ? window.RECAPTCHA_ENABLED === "1" : false,
  logsSystemClientToken: process.env.REACT_APP_EXTERNAL_LOGGER_CLIENT_TOKEN
    ? process.env.REACT_APP_EXTERNAL_LOGGER_CLIENT_TOKEN
    : (typeof window !== "undefined") ? window.EXTERNAL_LOGGER_CLIENT_TOKEN : "",
  socials: {
    fb: process.env.REACT_APP_FB_URL
      ? process.env.REACT_APP_FB_URL
      : "",
    yt: process.env.REACT_APP_YT_URL
      ? process.env.REACT_APP_YT_URL
      : "",
    instagram: process.env.REACT_APP_INSTAGRAM_URL
      ? process.env.REACT_APP_INSTAGRAM_URL
      : "",
    pin: process.env.REACT_APP_PIN_URL
      ? process.env.REACT_APP_PIN_URL
      : "",
    snapchat: process.env.REACT_APP_SNAPCHAT_URL
      ? process.env.REACT_APP_SNAPCHAT_URL
      : "",
    twitter: process.env.REACT_APP_TWITTER_URL
      ? process.env.REACT_APP_TWITTER_URL
      : "",
  },
  firebaseConfig: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY
      ? process.env.REACT_APP_FIREBASE_API_KEY
      : "",
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID
      ? process.env.REACT_APP_FIREBASE_PROJECT_ID
      : "",
    messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID
      ? process.env.REACT_APP_FIREBASE_SENDER_ID
      : "",
    appId: process.env.REACT_APP_FIREBASE_APP_ID
      ? process.env.REACT_APP_FIREBASE_APP_ID
      : "",
  },
  modules: [
    {
      ...Module.Dashboard,
      // example settings override
      // theme: {
      //   welcomeMessage: {
      //     backgroundColor: "red",
      //   }
      // }
    },
    Module.News,
    Module.KnowledgeBase,
    Module.Trainings,
    Module.Training,
    Module.Onboarding,
    Module.VideoPlayer,
    Module.Notifications,
    Module.Competence,
    Module.Surveys,
    Module.TeamIndicators,
    Module.Gamification,
    Module.Certificates,
    Module.Profile,
    Module.Webinars,
    Module.ScrollLesson,
    Module.AnonSurvey,
    Module.Form,
    Module.Search,
  ],
  timelineEnabled: process.env.REACT_APP_VIDEO_TIMELINE_ENABLED === "true",
};

export const Theme = {
  Sidebar: SidebarTheme.WithIcon,
  Avatar: AvatarTheme.Initials,
  DisabledMenuItems: DisabledItemTheme.Hidden,
  IDDFilter: IDDFilterTheme.Hidden,
  StatisticsTheme: StatisticsTheme.Hidden,
  CareerTheme: CareerTheme.Visible,
  CompetenceTagFiltering: TagFilteringTheme.Hidden,
  Brand: BrandTheme.Changeable,
  CompetenceType: CompetenceType.Strategic,
  TrainingPhoneLength: TrainingPhoneLength.Short,
  ProfileEdit: ProfileEditTheme.Default,
  Gamification: GamificationTheme.Gamification,
  ExternalRegistration: ExternalRegistration.Enabled,
  RegistrationColorTheme: Color.blue6,
  OnboardingWithDuties: false,
  OnboardingAll: OnboardingAllMode.Disabled,
  NewPasswordRemind: true,
  Tutorial: TutorialStatus.Enabled,
  AdvancedPasswordActive: false,
  Language: LanguageTheme.Multi,
};
