import React, { Component } from "react";
import i18n from "i18next";
import { connect } from "react-redux";
import MediaQuery from "react-responsive";

import ScrollLesson from "../modules/scrollLesson/scrollLesson.component";
import { CommonActions } from "../redux/common/common.reducer";
import { ThumbnailList } from "../components/thumbnailList/thumbnailList.component";
import { FavouriteIcon } from "../components/thumbnail/thumbnail.styled";
import { FavouriteItemType } from "../components/thumbnail/thumbnail.constants";
import { Tooltip } from "../components/tooltip/tooltip.component";
import { TagList } from "../components/tagList/tagList.component";
import { GlobalActions } from "../helpers/global.reducer";
import { SummaryShort } from "../quiz/summary";
import { UserRoleName } from "../userContext/userContext.constants";
import { renderWhenTrue } from "../helpers/rendering";
import { withLocation, withNavigation, withParams } from "../helpers/hocs";
import { deepClone } from "../helpers";
import { breakpoints } from "../UIGlobals";
import {
  HeaderBadge,
  MainTitle,
  ContentContainer,
  LoadingWrapper,
  BadgeWrap,
  NegativeMarginTopMobile,
  ReducePadding,
} from "../UIElements";
import { Separator } from "../theme";

import { LessonActions } from "./redux/lesson.reducer";
import { LessonsNav } from "./lessonsNav/lessonsNav.component";
import { Page } from "./page/page.component";
import { ErrorModal } from "./errorModal/errorModal.component";
import { WrappedQuiz, Summary } from "./quiz";
import {
  CommentsWrapper,
  CommentCounter,
  CommentList,
  RelatedVideosWrapper,
  BackButton,
  ButtonsWrapper,
  TagListWrapper,
  FavouriteListButton,
  Description,
  BoxWrapper,
  Current,
  Total,
  CurrentName,
  LessonInfo,
} from "./lesson.styled";

class Lesson extends Component {
  constructor() {
    super();
    this.state = {
      navigationOpened: false,
      isFavoriteIconActive: false,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.params.id !== this.props.params.id) {
      this.props.getData(nextProps.params.id);
    }

    if(nextProps.state.nav.currentParent !== this.props.state.nav.currentParent) {
      this.props.setUI("progress", 0);
      this.props.setUI("duration", 0);
      this.props.setUI("changedProgress", false);
    }
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.getData(this.props.params.id);
  }

  componentDidUpdate(prevProps) {
    const { state, user, location } = this.props;
    const onboardingLessonFinished = state.finished
      && state.quizes.length === 0
      && (location.state?.from === UserRoleName.Onboarding
      || user.data.user.role.name === UserRoleName.Onboarding);

    if (state.favorite !== prevProps.state.favorite) {
      this.setState({
        isFavoriteIconActive: state.favorite,
      });
    }

    if (onboardingLessonFinished) {
      this.navigateToList();
    }
  }

  handleToggleFavorite = () => {
    const groupId = this.props.state.groupsId?.find(() => true);
    if (this.state.isFavoriteIconActive) {
      this.props.removeFavouriteItem(
        groupId ? FavouriteItemType.ContentGroup : FavouriteItemType.Content,
        groupId || this.props.state.id,
      );
    } else {
      this.props.addFavouriteItem(
        groupId ? FavouriteItemType.ContentGroup : FavouriteItemType.Content,
        groupId || this.props.state.id,
      );
    }
    this.setState((state) => ({
      isFavoriteIconActive: !state.isFavoriteIconActive,
    }));
  };

  showNavigation() {
    this.setState({ navigationOpened: true });
  }

  hideNavigation() {
    this.setState({ navigationOpened: false });
  }

  navigate(type, pageId, slideId) {
    this.props.lessonNavigate(pageId, slideId);
  }

  submitComment = (values, actions) => {
    const { state, addComment } = this.props;
    addComment({ ...values, contentId: state.id });
    actions.setSubmitting(false);
    actions.resetForm();
  };

  editComment = (comment) => {
    this.props.editComment(comment);
  };

  deleteComment = (commentId) => {
    this.props.deleteComment(commentId);
  };

  generateTOC(pages, quizes, nav) {
    let toc = [];

    pages.forEach(page => {
      page.children.forEach(oldSlide => {
        const slide = deepClone(oldSlide);

        toc.push(slide);
      });
    });

    quizes.forEach(oldQuiz => {
      const quiz = deepClone(oldQuiz);

      toc.push(quiz);
    });

    return toc.map((item, key) => {
      switch (item.type) {
      case "slide":

        return {
          title: item.params.title,
          current: item.id === nav.currentChild,
          available:
              item.completed || item.id === nav.currentChild || (key > 1 && toc[key - 1].completed),
          id: item.id,
          parent: item.parentId,
          passed: item.params.passed,
          children: item.children,
          click: () => {
            this.props.navigate({ search: `slide=${key}` });
            this.navigate("slide", item.parentId, item.id);
          }
        };

      case "quiz":
        const title = item.params.find((param) => param.name === "title")?.value;
        const blocked = item.params.find((param) => param.name === "blocked")?.value;

        return {
          blocked,
          title: title ? title : i18n.t("lesson.lesson.test"),
          current: item.id === nav.currentParent,
          available: item.completed || item.children[0]?.id === nav.currentChild,
          id: item.id,
          children: item.children,
          click: () => {
            this.navigate("quiz", item.parentId, item.id);
          }
        };

      default:
        return {};
      }
    });
  }

  componentWillUnmount() {
    this.props.clearData();
  }

  navigateToList = () => {
    const { role } = this.props.user.data.user;
    const { state } = this.props.location;

    if (state?.isLastOnboardingTest) {
      this.props.navigate("/onboarding");
    } else if (state?.from === UserRoleName.Onboarding) {
      this.props.navigate("/onboarding#next", {
        currentView: state.currentView,
        currentStage: state.currentStage,
      });
    } else if (role.name === UserRoleName.Onboarding) {
      this.props.navigate("/onboarding#next");
    } else {
      window.history.state?.idx ? this.props.navigate(-1) : this.props.navigate("/lekcje");
    }
  };

  isVideo(currentSlide) {
    const { currentInteraction } = this.props.state.nav;

    if(currentSlide)
      return !!currentSlide
        .children
        .filter(slide =>slide.id === currentInteraction && slide.type === "video").length;
  }

  isScrollSlide(currentSlide) {
    const { currentInteraction } = this.props.state.nav;

    if (currentSlide) {
      return !!currentSlide.children.filter(
        (slide) => slide.id === currentInteraction && slide.params.find(
          (param) => param.name === "name" && param.value === "scrollContent")
      ).length;
    }
  }

  handleListClick = () => {
    this.showNavigation();
  }

  renderHeader = renderWhenTrue(() => (
    <HeaderBadge padding="50px 0" $hasBottomBorder>
      <MainTitle>{this.props.state.params.title}</MainTitle>
    </HeaderBadge>
  ));

  renderRelatedVideos = renderWhenTrue(() => {
    const relatedVideos = this.props.state.related;
    return (
      <RelatedVideosWrapper>
        <ThumbnailList title={i18n.t("lesson.related.alsoCheck")} thumbnails={relatedVideos} />
        <Separator />
      </RelatedVideosWrapper>
    );
  });

  renderCommentCounter = () => {
    const comments = this.props.state.comments || [];
    const label = comments.length === 0 || comments.length > 4
      ? i18n.t("lesson.comments.commentsLabel_1")
      : comments.length === 1
        ? i18n.t("lesson.comments.commentsLabel_2")
        : i18n.t("lesson.comments.commentsLabel_3");

    return `${comments.length} ${label}`;
  };

  render() {
    const { state, user, location } = this.props;
    if (!state) {
      return <div />;
    }
    const baseUrl = state.params.path;
    const toc = this.generateTOC(state.pages, state.quizes, state.nav);
    const { currentType, currentParent } = state.nav;
    const viewSource = currentType === "slide" ? state.pages : state.quizes;
    const currentViewIndex = viewSource.findIndex(parent => parent.id === currentParent);
    const currentView = viewSource[currentViewIndex];
    const loading = state.isLoading;
    const currentSlideIndex = toc.findIndex(slide => slide.current);
    const currentSlide = toc[currentSlideIndex];
    const isVideo = this.isVideo(currentSlide);
    const isScrollLesson = state.type === "lesson_slide_scroll";
    const isScrollContent = isScrollLesson || this.isScrollSlide(currentSlide);
    const finished = state.finished || currentSlide?.blocked;

    return (
      <ContentContainer
        background="white"
        isFullWidthLesson={(isVideo || isScrollContent) && currentType !== "quiz" && !finished}
      >
        {!isVideo && (
          <ButtonsWrapper>
            <BackButton
              isScrollContent={isScrollContent}
              defaultPath={user.data.user.role.name === UserRoleName.Onboarding ? "/onboarding" : "/lekcje"}
            />
            {user.data.user.availableRoutes?.api_user_favorite_add && (
              <Tooltip
                title={
                  this.state.isFavoriteIconActive
                    ? i18n.t("favouriteList.removeFromFavourites")
                    : i18n.t("favouriteList.addToFavourites")
                }
              >
                <FavouriteListButton
                  onClick={this.handleToggleFavorite}
                  $isFavourite={this.state.isFavoriteIconActive}
                >
                  <FavouriteIcon />
                </FavouriteListButton>
              </Tooltip>
            )}
          </ButtonsWrapper>
        )}
        <LoadingWrapper isLoading={loading}>
          <React.Fragment>
            {this.renderHeader(!isVideo && !isScrollContent)}
            <ErrorModal isOpen={state.isError} message={state.errorMessage} />
          </React.Fragment>

          {!finished && isScrollLesson && (
            <ReducePadding>
              <ScrollLesson data={state} baseUrl={baseUrl} />
            </ReducePadding>
          )}

          {!finished && !isScrollLesson && currentType === "slide" && (
            <React.Fragment>
              <ReducePadding>
                <LoadingWrapper isLoading={state.isPushing} isLesson>
                  <NegativeMarginTopMobile>
                    <Page
                      slides={currentView.children}
                      key={currentView.id}
                      baseUrl={baseUrl}
                      callComplete={() => {
                        alert("OK");
                        //this.setPageComplete(currentView.id);
                      }}
                      onListClick={this.handleListClick}
                      pageTitle={state.params.title}
                      currentSlideIndex={currentSlideIndex}
                      slidesOverall={toc.length}
                      tags={state.tags}
                    />
                  </NegativeMarginTopMobile>
                </LoadingWrapper>

                {isVideo ? (
                  <LessonsNav
                    hiddenButton
                    opened={this.state.navigationOpened}
                    toc={toc}
                    onHide={() => this.hideNavigation()}
                    onOpen={() => this.showNavigation()}
                  />
                ) : (
                  <>
                    {!!state.tags?.length && (
                      <TagListWrapper>
                        <TagList tags={state.tags} />
                      </TagListWrapper>
                    )}
                    <MediaQuery query={`(${breakpoints().minL})`}>
                      <LessonInfo $borderDisabled={true} padding="30px 25px" height={200}>
                        <Current>{currentSlideIndex + 1}</Current>
                        <Total>({toc.length})</Total>
                        <CurrentName>{currentSlide.title}</CurrentName>
                        <LessonsNav
                          opened={this.state.navigationOpened}
                          toc={toc}
                          onHide={() => this.hideNavigation()}
                          onOpen={() => this.showNavigation()}
                        />
                      </LessonInfo>
                    </MediaQuery>
                    <MediaQuery query={`(${breakpoints().maxL})`}>
                      <LessonInfo $borderDisabled={true} padding="20px 0" height={200}>
                        <React.Fragment>
                          <CurrentName>{currentSlide.title}</CurrentName>
                          <Current>{currentSlideIndex + 1}</Current>
                          <Total>({toc.length})</Total>
                        </React.Fragment>
                        <LessonsNav
                          opened={this.state.navigationOpened}
                          toc={toc}
                          onHide={() => this.hideNavigation()}
                          onOpen={() => this.showNavigation()}
                        />
                      </LessonInfo>
                    </MediaQuery>
                    {!!state.description && (
                      <Description $quizStyle>{state.description}</Description>
                    )}
                  </>
                )}
              </ReducePadding>
            </React.Fragment>
          )}

          {!finished && !isScrollLesson && currentType === "quiz" && (
            <React.Fragment>
              <LoadingWrapper isLoading={state.isPushing}>
                <WrappedQuiz questions={currentView.children}>
                  <BoxWrapper>
                    <BadgeWrap>
                      <LessonsNav
                        toc={toc}
                        opened={this.state.navigationOpened}
                        onHide={() => this.hideNavigation()}
                        onOpen={() => this.showNavigation()}
                      />
                    </BadgeWrap>
                  </BoxWrapper>
                </WrappedQuiz>
              </LoadingWrapper>
              {!!state.tags?.length && (
                <TagListWrapper>
                  <TagList tags={state.tags} />
                </TagListWrapper>
              )}
              <MediaQuery query={`(${breakpoints().maxL})`}>
                <LessonInfo padding="30px 25px" $borderDisabled={true} colorReverse={true} height={200}>
                  <Current>{toc.length}</Current>
                  <Total>({toc.length})</Total>
                  <CurrentName colorReverse={true}>{ currentSlide.title ? currentSlide.title : i18n.t("lesson.lesson.test") }</CurrentName>
                </LessonInfo>
              </MediaQuery>
              <MediaQuery query={`(${breakpoints().minL})`}>
                <LessonInfo padding="0" $borderDisabled={true}>
                  <Current>{toc.length}</Current>
                  <Total>({toc.length})</Total>
                  <CurrentName>{ currentSlide.title ? currentSlide.title : i18n.t("lesson.lesson.test") }</CurrentName>
                </LessonInfo>
              </MediaQuery>
              {!!state.description && (
                <Description>{state.description}</Description>
              )}
            </React.Fragment>
          )}
          {finished && state.quizes.length > 0 && (
            <Summary
              quiz={state.quizes[0]}
              actions={[{ label: i18n.t("lesson.lesson.comeBackToList"), action: this.navigateToList }]}
            />
          )}
          {finished
            && state.quizes.length === 0
            && location.state?.from !== UserRoleName.Onboarding
            && user.data.user.role.name !== UserRoleName.Onboarding && (
            <SummaryShort
              actions={[{ label: i18n.t("lesson.lesson.comeBackToList"), action: this.navigateToList }]}
              wrapped={isVideo || isScrollContent}
            />
          )}
          <CommentsWrapper $withRelatedVideos={state.relatedVideos && !!state.relatedVideos.length}>
            {this.renderRelatedVideos(!!state.related.length)}
            <CommentCounter>
              {this.renderCommentCounter()}
            </CommentCounter>
            <CommentList
              comments={state.comments}
              hideDisabled
              onSubmit={this.submitComment}
              onEdit={this.editComment}
              onDelete={this.deleteComment}
            />
          </CommentsWrapper>
        </LoadingWrapper>
      </ContentContainer>
    );
  }
}

const mapStateToProps = state => ({
  state: state.lesson ? { ...state.lesson, ui: state.ui } : undefined,
  user: state.userContext,
  profile: state.profile
});

const mapDispatchToProps = dispatch => ({
  lessonNavigate: (pageId, slideId) => dispatch(LessonActions.setCurrentSlide(pageId, slideId)),
  getData: id => dispatch(LessonActions.getInitialData(id)),
  clearData: () => dispatch(LessonActions.clearData()),
  setUI: (param, value) => dispatch(GlobalActions.setUI(param, value)),
  addComment: (value) => dispatch(LessonActions.addComment(value)),
  editComment: (comment) => dispatch(CommonActions.editLessonComment(comment)),
  deleteComment: (commentId) => dispatch(CommonActions.deleteLessonComment(commentId)),
  addFavouriteItem: (type, id) => dispatch(CommonActions.addFavouriteItem(type, id)),
  removeFavouriteItem: (type, id) => dispatch(CommonActions.removeFavouriteItem(type, id)),
});

export default withLocation(withNavigation(withParams(
  connect(mapStateToProps, mapDispatchToProps)(Lesson)
)));
