import React from "react";

import { colors } from "../../UIGlobals";

import { Wrapper } from "./logo.styled";
import { Icon } from "../index";

const Logo = ({ cursorPointer }) => (
  <Wrapper>
    <Icon
      icon="platform_owner_logo"
      size={18}
      color={colors().black}
      cursor={cursorPointer && "pointer"}
    />
  </Wrapper>
);

export default Logo;
