import styled from "styled-components";

export const Container = styled.div`
  font-size: 20px;
  height: 670px;

  .put-in-order--header {
    text-align: center;
    font-size: 1em;
    padding: 1em 0;
    margin: 0 0 1em;
    border-bottom: 1px solid #ddd;
    color: #222;
    width: 100%;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    line-height: 25px;
  }

  .put-in-order--task-screen {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }

  .put-in-order--task-screen--item {
    padding: 1em 1.4em;
    background-color: #14a6d5;
    color: #fff;
    cursor: pointer;
    font-family: "Akzidenz", sans-serif;
    text-align: center;
    font-size: 14px;
    text-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    transition: background 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    max-width: 100%;
    margin: 0 15px .5em 15px;
  }

  .put-in-order--task-screen--item:hover {
    background-color: #19b7e9;
  }

  .put-in-order--task-screen--item.ready {
    transition: background 0.2s ease-out;
  }

  .put-in-order--button {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 46px;
    margin: 40px 0 0;
    padding: 0 30px 0 0;
    background: #0c6a8a;
    border: none;
    border-radius: 23px;
    color: #fff;
    cursor: pointer;
    font-size: .7em;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 1px;
    outline: none;
    transition: background 0.2s ease-out, transform 0.2s ease-out, opacity 0.2s ease-out;
    opacity: 1;
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }

  .put-in-order--button:hover {
    background-color: #0e7ca1;
  }

  .put-in-order--button:after {
    position: absolute;
    height: 60px;
    width: 60px;
    border-radius: 30px;
    content: '';
    top: -7px;
    right: -1px;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAPNJREFUeNpi/P//P8NAAiaGAQZDwgHiQMw5UA6YCsQvgPgbENfTxAWgRIgHo4O9QBxMQA9JGJ8k83/coB+IxQfSATCQO9AOAIH5QGw6kA6AgeaBdgBZiZTaDkBOpMID6QCiEymti+JJQDwfiE3JKYioEQLIoBKbPYx4qmNmIP5D5RBhA+LfI7Y6rkL3PQiw0MHiBUA8DYhPY5OktQPygHjyQETBBCCWIGQ5LULgADS4V1OjQUKXyogaIbAI6uuT1G6SERMChcO6SYatUbofiEPp1SgF4alIlrdS02JiKiMYkAbiD0D8lRYFBuNo53SgHQAQYAB7r+TNHC0c4QAAAABJRU5ErkJggg==') 16px center no-repeat;
    background-color: inherit;
  }

  .put-in-order--result-screen {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  & .hidden {
    opacity: 0;
    transform: translate3d(0, 60px, 0) scale3d(0.9, 0.9, 1);
  }
  
  .put-in-order--task-screen--item.active {
    background-color: #1082a6;
  }
`;
